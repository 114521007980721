<i18n lang="json5">{
	ru: {
		avia_tagline_top: 'Предложим лучшие варианты',
		avia_tagline_bottom: 'дешевле, только если идти пешком',
		insurance_tagline_top: 'Рекомендуем застраховать поездку',
		insurance_tagline_bottom: 'обеспечив защиту в случае болезни, утраты багажа, отмены рейса',
		transfer_tagline_top: 'Позаботьтесь о трансфере заранее',
		transfer_tagline_bottom: 'заказав его онлайн, Вы сэкономите деньги и сохраните нервы',
		train_tagline_top: 'Самый надёжный самолет – это поезд',
		train_tagline_bottom: 'билет на который куплен онлайн без очередей и шапок',
		tab_avia: 'Авиабилеты',
		tab_insurance: 'Страхование',
		tab_train: 'Поезд',
		tab_bus: 'Автобус',
		tab_hotel: 'Отель',
		tab_transfer: 'Трансфер',
		tab_business_lounge: 'Бизнес-зал',
		bookhara_guarantees: 'BOOKHARA это гарантия:',
		guarantees_list: '• Экономии на услугах авиакасс;\n• Оперативной поддержки;\n• Безопасной оплаты онлайн;\n• Возможности отмены без штрафов;\n• Предоставления достоверной информации;\n• Предложений от более 600 авиакомпаний.',
	},
	uz: {
		avia_tagline_top: 'Biz bilan safar qiling',
		avia_tagline_bottom: 'Bundan arzoni faqat piyoda',
		insurance_tagline_top: 'Safaringizni sugʼurtalang',
		insurance_tagline_bottom: 'va kasallik yetganda, yuk yoʼqolganda yoki reys bekor qilinganda ham xotirjam boʼling',
		transfer_tagline_top: 'Позаботьтесь о трансфере заранее',
		transfer_tagline_bottom: 'заказав его онлайн, Вы сэкономите деньги и сохраните нервы',
		train_tagline_top: 'Самый надёжный самолет – это поезд',
		train_tagline_bottom: 'билет на который куплен онлайн без очередей и шапок',
		tab_avia: 'Aviachiptalar',
		tab_insurance: 'Sugʼurta',
		tab_train: 'Poyezd',
		tab_bus: 'Avtobus',
		tab_hotel: 'Mehmonxona',
		tab_transfer: 'Transfer',
		tab_business_lounge: 'Biznes zali',
		bookhara_guarantees: 'BOOKHARA – bu:',
		guarantees_list: '• aviakassa xizmatlaridan tejab qolish;\n• xavfsiz onlayn toʼlash;\n• jarimalarsiz chiptani bekor qilish;\n• yukni yoʼqotish va reys kechikishidan sugʼurtalash;\n• 600 ta aviakompaniya takliflaridan foydalanish kafolatidir.',
	},
}</i18n>

<template>
	<div class="flex flex-col xl:container xl:mx-auto lg:h-full">
		<div class="flex flex-col justify-center grow pt-6 sm:pt-12 lg:pt-28 lg:pb-20">
			<keep-alive>
				<TabInsurance v-if="activeTab === 'insurance'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('insurance_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('insurance_tagline_bottom') }}
						</h3>
						<InsuranceSearchForm />
					</div>
				</TabInsurance>
				<TabTransfer v-if="activeTab === 'transfer'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('transfer_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('transfer_tagline_bottom') }}
						</h3>
						<TransferSearchForm />
					</div>
				</TabTransfer>
				<TabTrain v-if="activeTab === 'train'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('train_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('train_tagline_bottom') }}
						</h3>
						<TrainSearchForm />
					</div>
				</TabTrain>
				<TabAvia v-else inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('avia_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('avia_tagline_bottom') }}
						</h3>
						<AviaSearchForm />
					</div>
				</TabAvia>
			</keep-alive>
		</div>

		<div class="mt-20 lg:mt-0 mb-20">
			<div>
				<hr class="border-my-gray border-t"/>

				<div class="flex flex-nowrap overflow-x-auto">
					<div
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'avia'}"
						@click="activeTab = 'avia'"
					>
						{{ $t('tab_avia') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_bus') }}
					</div>
					<div
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'insurance'}"
						@click="activeTab = 'insurance'"
					>
						{{ $t('tab_insurance') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_hotel') }}
					</div>
					<div
						v-if="testServer"
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'transfer'}"
						@click="activeTab = 'transfer'"
					>
						{{ $t('tab_transfer') }}
					</div>
					<div
						v-if="testServer"
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'train'}"
						@click="activeTab = 'train'"
					>
						{{ $t('tab_train') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_business_lounge') }}
					</div>
				</div>
			</div>
		</div>

		<div class="md:hidden mt-12">
			<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
				{{ $t('bookhara_guarantees') }}
			</h2>
			<div class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12 whitespace-pre-line">
				{{ $t('guarantees_list') }}
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import AviaSearchForm from '~/components/AviaSearchForm'
import InsuranceSearchForm from '~/components/InsuranceSearchForm'
import TransferSearchForm from '~/components/TransferSearchForm'
import TrainSearchForm from '~/components/TrainSearchForm'
import {testServer} from '../../nuxt.config.js'

Vue.component('TabAvia', {
	components: {
		AviaSearchForm,
	},
})

Vue.component('TabInsurance', {
	components: {
		InsuranceSearchForm,
	},
})

Vue.component('TabTransfer', {
	components: {
		TransferSearchForm,
	},
})

Vue.component('TabTrain', {
	components: {
		TrainSearchForm,
	},
})

export default {
	name: 'Home',
	data() {
		return {
			activeTab: this.$route.query.tab || 'avia',
			testServer: testServer,
		}
	},
	watch: {
		activeTab(newTab, oldTab) {
			this.$parent.$refs.home.classList.remove('bg-' + oldTab)
			this.$parent.$refs.home.classList.add('bg-' + newTab)
			window.scrollTo(0, 0)
			this.$router.replace({name: 'home', query: {tab: newTab}})
		},
		async $route(to) {
			this.activeTab = to.query.tab || 'avia'
		},
	},
}
</script>
